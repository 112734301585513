<template>
  <div class="page">
    <Navbar :isButton="showButton" :mode="mode" id="navbar" />
    <header class="page-header">
      <div class="page-header-wrapper">
        <div class="page-header-container">
          <h1 class="page-header-title" v-t="'page.about.title'"/>
          <p class="page-header-text" v-t="'page.about.excerpt'"/>
        </div>
      </div>
    </header>
    <main class="page-content">
      <section class="page-section">
        <div class="page-section-article flex-row-reverse">
          <figure class="page-section-article-img-container">
            <img
              src="@/assets/img/heroes-sections/traveller-passport.jpg"
              alt="Traveller holding a passport"
              class="page-section-article-img"
            />
          </figure>
          <div class="page-section-article-text">
            <h2 v-t="'page.about.section.mission.title'"/>
            <p v-html="$t('page.about.section.mission.html')"/>
          </div>
        </div>
      </section>
      <section class="page-section">
        <h2 v-t="'page.about.section.core_values.title'"/>
        <ul class="items-grid">
          <li>
            <div class="icon-container">
              <i class="bx bxs-face"></i>
            </div>
            <div class="item-text-container">
              <h4 v-t="'page.about.section.core_values.children_at_heart.title'"/>
              <p v-t="'page.about.section.core_values.children_at_heart.text'"/>
            </div>
          </li>
          <li>
            <div class="icon-container">
              <i class="bx bxs-heart-circle"></i>
            </div>
            <div class="item-text-container">
              <h4 v-t="'page.about.section.core_values.true_to_ourselves.title'"/>
              <p v-t="'page.about.section.core_values.true_to_ourselves.text'"/>
            </div>
          </li>
          <li>
            <div class="icon-container">
              <i class="bx bxs-group"></i>
            </div>
            <div class="item-text-container">
              <h4 v-t="'page.about.section.core_values.work_together.title'"/>
              <p v-t="'page.about.section.core_values.work_together.text'"/>
            </div>
          </li>
          <li>
            <div class="icon-container">
              <i class="bx bxs-trophy"></i>
            </div>
            <div class="item-text-container">
              <h4 v-t="'page.about.section.core_values.embrace_the_challenge.title'"/>
              <p v-t="'page.about.section.core_values.embrace_the_challenge.text'"/>
            </div>
          </li>
          <li>
            <div class="icon-container">
              <i class="bx bxs-star"></i>
            </div>
            <div class="item-text-container">
              <h4 v-t="'page.about.section.core_values.put_customers_first.title'"/>
              <p v-t="'page.about.section.core_values.put_customers_first.text'"/>
            </div>
          </li>
        </ul>
      </section>
      <section class="page-section">
        <div class="page-section-article">
          <figure class="page-section-article-img-container">
            <img
              src="@/assets/img/heroes-sections/partner-in-safari.jpg"
              alt="Local guide driving in a safari"
              class="page-section-article-img"
            />
          </figure>
          <div class="page-section-article-text">
            <h2 v-t="'page.about.section.our_local_agencies.title'"/>
            <p v-t="'page.about.section.our_local_agencies.text'"/>
          </div>
        </div>
      </section>
    </main>
    <Footer />
    <BottomBar class="layout-bar" />
  </div>
</template>

<script>
import BottomBar from "@/components/layout/BottomBar";

export default {
  name: "About",
  components: { BottomBar },
  data() {
    return {
      bottomBarPosition: "-bottom-full",
      mode: "transparent",
      showButton: false,
    };
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Travel Afrique – About',
  },
  methods: {
    switchModeOnScroll() {
      let headerTitle = document.querySelector(".page-header-title");
      let navbar = document.querySelector(".navbar");

      if (
        navbar.getBoundingClientRect().bottom >
        headerTitle.getBoundingClientRect().top
      )
        this.mode = "solid";
      else this.mode = "transparent";
    },
  },
  created() {
    window.addEventListener("scroll", this.showBottomBarOnScroll);
    window.addEventListener("scroll", this.switchModeOnScroll);
  },
  destroyed() {
    window.addEventListener("scroll", this.showBottomBarOnScroll);
    window.addEventListener("scroll", this.switchModeOnScroll);
  },
};
</script>

<style scoped>
.page-header {
  @apply w-full h-148 relative;
}

.page-header figure {
  @apply w-full h-full relative;
}

.page-header figure img {
  @apply absolute w-full h-full;
  @apply object-cover;
}

#navbar {
  @apply fixed top-0 z-20 left-0;
}

.page-header {
  @apply flex items-center justify-center;
  @apply px-4 pt-32 pb-20 bg-teal-dark sm:pt-40 md:px-12;
}

.page-header-wrapper {
  @apply w-full max-w-screen-xl md:max-w-screen-md;
}

.page-header-title {
  @apply w-full mb-6;
  @apply font-bold font-sans leading-tight text-center text-white;
  @apply text-2xl md:text-3xl lg:text-4xl;
}

.page-header-text {
  @apply w-full text-center text-grey-base text-base sm:text-xl;
}

.page-content {
  @apply px-4 py-20 md:px-12;
}

.page-section {
  @apply w-full max-w-screen-xl md:max-w-screen-md;
  @apply relative transform -translate-x-1/2 left-1/2;
  @apply mt-24 text-black-base;
}

.page-section:not(:last-child) {
  @apply border-b border-grey-base pb-16;
}

.page-section h2 {
  @apply font-semibold text-3xl mb-2 sm:text-4xl sm:mb-8 text-black-base;
}

.page-section-article {
  @apply flex justify-between gap-6 sm:gap-8 flex-wrap sm:flex-nowrap;
}

.page-section-article-img-container {
  @apply h-0 overflow-hidden pb-64 relative  w-full sm:w-calc-5/12 rounded-md mx-auto sm:mx-0;
}

.page-section-article-img {
  @apply w-full h-full absolute left-0 top-0 object-cover;
}

.page-section-article-text {
  @apply w-full text-center sm:text-left sm:w-calc-7/12;
}

.page-section-article-text p {
  @apply text-black-lighter;
}

.items-grid {
  @apply grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 grid-flow-row gap-4;
}

.items-grid li {
  @apply px-8 py-8 border border-grey-base rounded-lg;
}

.icon-container {
  @apply bg-yellow-lighter inline-flex items-center justify-center;
  @apply mb-2 p-4 rounded-full;
}

.items-grid i {
  @apply text-yellow-dark text-2xl;
}

.items-grid h4 {
  @apply font-semibold text-xl text-black-base;
  @apply mb-1;
}

.items-grid p {
  @apply text-black-lighter;
}
.layout-bar {
  @apply fixed bottom-0 w-full md:hidden;
}
</style>